const osData = {
  banners: ['/pic/banner/banner01.png', '/pic/banner/banner02.png', '/pic/banner/banner03.png'],
  typeMap: {
    app: 'app/小程序开发',
    game: '游戏',
    ics: '工业控制系统',
    crm: 'ERP/OA/CRM系统',
  },
  caseBanner: '/pic/banner/case_banner.png',
  appList: [
    {
      id: 'app01',
      type: 'app',
      mainImg: '/pic/app01/main.png',
      title: 'T社交',
      desc: '是一款全新的社交应用程序，旨在为用户提供一个互动、连接和分享的平台。为用户提供一个友好、安全和有趣的社交体验。我们致力于不断改进和更新应用程序，以满足用户的需求和期望。',
      info: ['/pic/app01/detail1.jpg'],
    },
    {
      id: 'app02',
      type: 'app',
      mainImg: '/pic/app02/main.png',
      title: '占卜项目',
      desc: '旨在为用户提供便捷、准确和个性化的塔罗牌占卜服务。通过结合先进的移动技术和精心设计的用户界面，该应用程序将为用户带来塔罗牌占卜、综合解析、个性化体验、分享和社交功能等核心功能。',
      info: ['/pic/app02/detail1.jpg'],
    },
    {
      id: 'app03',
      type: 'app',
      mainImg: '/pic/app03/main.png',
      title: 'K12儿童教育项目',
      desc: '该项目是一款专注于提供优质教育资源和儿童学习支持的应用程序。该应用旨在通过丰富多样的教育内容、互动学习工具和趣味活动，帮助儿童在游戏中学习、发展技能，并促进他们的全面成长。',
      info: ['/pic/app03/detail1.jpg'],
    },
    {
      id: 'app04',
      type: 'app',
      mainImg: '/pic/app04/main.png',
      title: '影视项目',
      desc: '该项目是一款专为影视爱好者和行业专业人士设计的应用程序。该应用旨在提供全面的影视资讯、观影体验和社交互动功能，为用户打造一个便捷、全面的影视世界。',
      info: ['/pic/app04/detail1.jpg'],
    },
    {
      id: 'app05',
      type: 'app',
      mainImg: '/pic/app05/main.png',
      title: '医疗项目',
      desc: '该项目是一款致力于提供便捷、全面的医疗服务的应用程序。该应用旨在满足用户在医疗健康领域的各种需求，提供在线预约、健康咨询、用药提醒、健康数据管理等功能，为用户提供全方位的医疗保健支持。',
      info: ['/pic/app05/detail1.jpg'],
    },
    {
      id: 'app06',
      type: 'app',
      mainImg: '/pic/app06/main.png',
      title: '一点清理助手',
      desc: '该项目是一款旨在帮助用户优化手机性能、释放存储空间和提升使用体验的应用程序。该应用通过一系列功能和工具，帮助用户清理垃圾文件、管理应用程序、加速手机运行等，使手机保持高效、流畅的状态。',
      info: ['/pic/app06/detail1.jpg'],
    },
    {
      id: 'app07',
      type: 'app',
      mainImg: '/pic/app07/main.png',
      title: '健康运动项目',
      desc: '该项目一款专注于跑步健身的应用程序。该应用旨在帮助用户记录跑步活动、提供跑步计划和指导、激励用户保持锻炼习惯，并提供个性化的跑步体验，促进用户的健康和身体素质的提升。',
      info: ['/pic/app07/detail1.jpg'],
    },
    {
      id: 'app08',
      type: 'app',
      mainImg: '/pic/app08/main.png',
      title: '房产项目',
      desc: '该项目是一款致力于提供全面、便捷的房产信息和服务的应用程序。该应用旨在帮助用户轻松寻找理想的房产，提供房屋出售、出租信息，以及房产购买、租赁的相关服务和工具。',
      info: ['/pic/app08/detail1.jpg'],
    },
    {
      id: 'game01',
      type: 'game',
      mainImg: '/pic/game01/main.png',
      title: '合成类小游戏项目',
      desc: '该项目是一款创意独特、富有策略性的游戏应用程序。该应用旨在通过合成和组合不同元素、物品或角色，让玩家体验创造和进化的乐趣，探索无限可能性，培养策略思维和创造力。',
      info: ['/pic/game01/detail1.jpg'],
    },
    {
      id: 'game02',
      type: 'game',
      mainImg: '/pic/game02/main.png',
      title: '倒水类游戏项目',
      desc: '倒水合成类小游戏是一类流行的益智游戏，其玩法基于将不同容器中的液体倒入目标容器中，通过合理操作实现液体的转移和合成。这类游戏通常具有简单而直观的操作，适合各个年龄段的玩家。',
      info: ['/pic/game02/detail1.jpg'],
    },
    {
      id: 'game03',
      type: 'game',
      mainImg: '/pic/game03/main.png',
      title: '游戏玩家app',
      desc: '致力于为游戏玩家提供一个综合性的平台，让您能够更好地享受游戏、与其他玩家互动并提升自己的游戏技能，内含游戏资讯和评价，社交互动，游戏统计和成就，游戏推荐和订阅等。',
      info: ['/pic/game03/detail1.jpg'],
    },
    {
      id: 'ics01',
      type: 'ics',
      mainImg: '/pic/ics01/main.png',
      title: '工控机项目',
      desc: '该管理平台是一种用于监测和管理大型环境检测系统的软件平台。它通过集成和展示环境监测设备所收集的数据，提供实时的环境监测信息和运营管理功能，帮助用户有效监控和管理环境质量。',
      info: ['/pic/ics01/detail1.jpg'],
    },
    {
      id: 'crm01',
      type: 'crm',
      mainImg: '/pic/crm01/main.png',
      title: '影视项目及影视管理系统',
      desc: '该系统用于管理和处理电影票务销售和预订的各个环节，包括场次管理、票务销售、座位管理、订单处理等。通过集成和管理电影票务销售流程的各个环节，提供了高效的票务管理和订单处理功能。',
      info: ['/pic/crm01/detail1.jpg'],
    },
    {
      id: 'crm02',
      type: 'crm',
      mainImg: '/pic/crm02/main.png',
      title: '停车场管理系统',
      desc: '停车场后台管理平台是为停车场管理者设计的管理系统，旨在帮助管理者高效地运营和管理停车场的各个方面。该平台通常提供了一系列功能和工具，用于停车场的车辆管理、收费管理、安全监控、数据分析等。',
      info: ['/pic/crm02/detail1.jpg'],
    },
    {
      id: 'crm03',
      type: 'crm',
      mainImg: '/pic/crm03/main.png',
      title: '医院管理系统',
      desc: '该系统是一种用于协调和管理医院日常运营的后台解决方案。该系统分为患者模式、医生模式以及最高权限管理者模式。它提供了一个集中管理医院各个方面的平台，包括患者管理等功能。该系统旨在提高医院的工作效率、降低人力成本...',
      info: ['/pic/crm03/detail1.jpg'],
    },
  ],
};
export default osData;
