import { useEffect, useState } from 'react';
import osData from '../config';
import { useNavigate } from 'react-router-dom';
export default function HomePage({ link }) {
  const [selectType, setSelectType] = useState(Object.keys(osData.typeMap)[0]);
  const list = osData.appList.filter((i) => i.type === selectType);
  const navigate = useNavigate();
  useEffect(() => {
    if (link) {
      let v = document.getElementById(link);
      if (v) {
        v.scrollIntoView();
      }
    }
  }, [link]);

  return (
    <div className="home-page">
      <div id="product" className="one-block d-flex-c ai-center">
        <div className="main-width">
          <div className="text-center mb-60 font-32 font-w-6">专业优质的软件服务</div>
          <div className="d-flex-r pb-60 jc-between">
            <div className="one-item box-shadow">
              <div className="d-flex-r ai-center">
                <img className="icon mr-8 ml-15" src="/pic/icon_code.png" alt="" />
                <div className="flex-1">
                  <div className="font-18 mb-8">高品质应用开发</div>
                  <div className="font-12 text-color-7">Application development</div>
                </div>
              </div>
              <div className="value-top text-justify">
                我们拥有一支经验丰富的开发团队，熟悉各种移动开发技术和平台，能够为您提供定制化的应用解决方案。我们注重细节和用户体验，确保每个应用都能达到最高的质量标准。
              </div>
            </div>
            <div className="one-item box-shadow">
              <div className="d-flex-r ai-center">
                <img className="icon mr-8 ml-15" src="/pic/icon_psd.png" alt="" />
                <div className="flex-1">
                  <div className="font-18 mb-8">创意设计用户界面</div>
                  <div className="font-12 text-color-7">Design interface</div>
                </div>
              </div>
              <div className="value-top text-justify">
                我们的设计团队擅长创意设计和用户界面设计，能够为您的应用提供独特且吸引人的外观。我们注重用户体验和界面交互，确保用户在使用应用时获得良好的体验。
              </div>
            </div>
            <div className="one-item box-shadow">
              <div className="d-flex-r ai-center">
                <img className="icon mr-8 ml-15" src="/pic/icon_scrim.png" alt="" />
                <div className="flex-1">
                  <div className="font-18 mb-8">敏捷开发方法</div>
                  <div className="font-12 text-color-7">Development method</div>
                </div>
              </div>
              <div className="value-top text-justify">
                我们采用敏捷开发方法，以快速响应客户需求和变化。我们与客户密切合作，及时反馈和调整，确保项目按时交付，并保持良好的沟通和合作关系。
              </div>
            </div>
            <div className="one-item box-shadow">
              <div className="d-flex-r ai-center">
                <img className="icon mr-8 ml-15" src="/pic/icon_customer.png" alt="" />
                <div className="flex-1">
                  <div className="font-18 mb-8">全方位技术支持</div>
                  <div className="font-12 text-color-7">Technical support</div>
                </div>
              </div>
              <div className="value-top text-justify">
                我们提供全方位的技术支持和维护服务，确保您的应用在上线后能够平稳运行。我们定期进行更新和优化，以适应不断变化的技术和市场需求。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="two-block d-flex-c ai-center">
        <div className="main-width">
          <div className="text-center mb-60 font-32 font-w-6">专注于为各个行业和领域</div>
          <div className="d-flex-r jc-center ai-center mb-40">
            <img className="pic mr-163" src="/pic/pic_home_1.png" alt="" />
            <div>
              <div className="font-18 font-w-6 mb-34">电子商务行业</div>
              <div className="text-justify top-word">
                我们专注于开发定制化的移动应用，以提升用户购物体验和增加销售。我们的应用可以提供在线购物、支付、订单追踪等功能，让用户能够方便地浏览和购买产品，实现安全快捷的支付，以及跟踪订单的状态。
              </div>
            </div>
          </div>
          <div className="d-flex-r jc-center ai-center mb-40">
            <div>
              <div className="font-18 font-w-6 mb-34">社交媒体行业</div>
              <div className="text-justify top-word">
                我们致力于开发创新和功能丰富的社交媒体应用，帮助用户建立社交网络和互动平台。我们的应用可以包括用户注册、个人资料管理、社交互动、信息分享等功能，让用户能够方便地与朋友、家人和同事保持联系，并分享自己的想法和生活。
              </div>
            </div>
            <img className="pic ml-163" src="/pic/pic_home_2.png" alt="" />
          </div>
          <div className="d-flex-r jc-center ai-center mb-40">
            <img className="pic mr-163" src="/pic/pic_home_3.png" alt="" />
            <div>
              <div className="font-18 font-w-6 mb-34">旅游和酒店行业</div>
              <div className="text-justify top-word">
                我们的应用可以开发旅游指南、酒店预订、景点推荐等功能，让用户能够方便地规划旅行行程，预订酒店，了解当地的景点和文化，并获得个性化的旅游建议。
              </div>
            </div>
          </div>
          <div className="d-flex-r jc-center ai-center mb-40">
            <div>
              <div className="font-18 font-w-6 mb-34">娱乐和游戏行业</div>
              <div className="text-justify  top-word">
                我们可以开发各种类型的娱乐和游戏应用，包括休闲游戏、益智游戏、多人游戏等，为用户提供丰富多样的娱乐选择。我们注重游戏的设计和用户体验，让用户能够享受到有趣、刺激和令人满足的游戏体验。
              </div>
            </div>
            <img className="pic ml-163" src="/pic/pic_home_4.png" alt="" />
          </div>
        </div>
      </div>
      <div className="two-block d-flex-c ai-center">
        <div className="main-width">
          <div className="text-center mb-60 font-32 font-w-6">案例展示</div>
          <div className="d-flex-r jc-center mb-60">
            {Object.keys(osData.typeMap).map((i) => (
              <div
                className={`btn handle mr-27 ${selectType === i ? 'active' : ''}`}
                key={i}
                onClick={() => setSelectType(i)}
              >
                {osData.typeMap[i]}
              </div>
            ))}
          </div>
          <div className="app-block">
            {list.map((i) => (
              <div className="app-item box-shadow" key={i.id} onClick={() => navigate(`/caseDetail/${i.id}`)}>
                <img className="pic-main" src={i.mainImg} alt="" />
                <div className="title font-16 font-w-6 pl-10">{i.title}</div>
                <div className="desc text-justify">{i.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
