import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './pages/navbar';
import HomePage from './pages/homePage';
import CasePage from './pages/caseListPage';
import CaseDetailPage from './pages/caseDetailPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/product" element={<HomePage link="product" />} />
          <Route path="/case" element={<CasePage />} />
          <Route path="/about" element={<HomePage link="about" />} />
          <Route path="/conact" element={<HomePage link="conact" />} />
          <Route path="/caseDetail/:id" element={<CaseDetailPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
