import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import osData from '../config';

export default function CaseDetailPage() {
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  let detail = osData.appList.find((i) => i.id === id);
  let list = [];
  let has = [];
  while (list.length < 8) {
    let random = Math.floor(Math.random() * osData.appList.length);
    if (random < osData.appList.length && !has.includes(random) && osData.appList[random].id !== id) {
      list.push(osData.appList[random]);
      has.push(random);
    }
  }
  return (
    <div className="case-detail-page d-flex-c ai-center">
      <div className="main-width">
        <div className="mt-40 pb-10 nav-link">
          您当前的位置&nbsp;&gt;&nbsp;
          <span className="handle" onClick={() => navigate('/')}>
            主页
          </span>
          &nbsp;&gt;&nbsp;
          <span className="handle" onClick={() => navigate('/case')}>
            案例
          </span>
          &nbsp;&gt;&nbsp;
          <span className="font-w-6">案例详情</span>
        </div>
        <div className="d-flex-c ai-center">
          <div className="font-30 text-center mt-68 nav-link title pb-15">{detail.title}</div>
        </div>
        <div className="font-32 mt-15 bw-1 text-justify mb-60">{detail.desc}</div>
        <div className="d-flex-c">
          {detail.info.map((i) => (
            <img key={i} className="bw-1" src={i} alt="" />
          ))}
        </div>
        <div className="d-flex-r jc-between ai-end nav-link mt-80 mb-60">
          <div className="font-32 font-w-6 more">相关案例</div>
          <div className="handle font-18" onClick={() => navigate('/case')}>
            查看更多&gt;
          </div>
        </div>
        <div className="app-block">
          {list.map((i) => (
            <div className="app-item box-shadow" key={i.id} onClick={() => navigate(`/caseDetail/${i.id}`)}>
              <img className="pic-main" src={i.mainImg} alt="" />
              <div className="app-title font-16 font-w-6 pl-10">{i.title}</div>
              <div className="desc text-justify">{i.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
