import { useNavigate } from 'react-router-dom';
import osData from '../config';
import { useEffect } from 'react';

export default function CaseListPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="case-list-page d-flex-c ai-center">
      <div className="main-width">
        <div className="mt-40 pb-10 nav-link">
          您当前的位置&nbsp;&gt;&nbsp;
          <span className="handle" onClick={() => navigate('/')}>
            主页
          </span>
          &nbsp;&gt;&nbsp;
          <span className="font-w-6">案例</span>
        </div>
        <div className="font-32 font-w-6 text-center mt-68">案例展示</div>
        <div className="font-18 mt-15 text-center">用心服务 追求卓越品质</div>
        <div className="app-block mt-60">
          {osData.appList.map((i) => (
            <div className="app-item box-shadow" key={i.id} onClick={async () => navigate(`/caseDetail/${i.id}`)}>
              <img className="pic-main" src={i.mainImg} alt="" />
              <div className="title font-16 font-w-6 pl-10">{i.title}</div>
              <div className="desc text-justify">{i.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
