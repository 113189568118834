import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import osData from '../config';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const defaultSlide = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
};
const caseSlide = {
  dots: false,
  infinite: false,
  speed: 500,
  autoplay: false,
};

const tabRoute = ['/', '/product', '/case', '/about', '/conact'];
export default function NavBar() {
  const location = useLocation();
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const bannerRef = useRef({ dots: true, infinite: true, speed: 500, autoplay: true });
  useEffect(() => {
    let t = tabRoute.indexOf(location.pathname);
    if (t >= 0 && t < tabRoute.length) {
      // bannerRef.current = defaultSlide;
      setSelect(t);
    } else if (location.pathname.includes('/caseDetail')) {
      // bannerRef.current = caseSlide;
      setSelect(2);
    }
  }, [location]); //eslint-disable-line

  function handleTab(idx) {
    setSelect(idx);
    navigate(tabRoute[idx]);
  }

  return (
    <div>
      <div className="nav-bar d-flex-c ai-center">

        <div className="main-width">
          <div className="header d-flex-r ai-center font-16">
            <img className="nav-logo" src="/pic/pic_logo.png" alt="" />
            <div className={`nav-item handle ${select === 0 ? 'active' : ''}`} onClick={() => handleTab(0)}>
              <div className="item-label">首页</div>
              <div className="line" />
            </div>
            <div className={`nav-item handle ${select === 1 ? 'active' : ''}`} onClick={() => handleTab(1)}>
              <div className="item-label">产品与服务</div>
              <div className="line" />
            </div>
            <div className={`nav-item handle ${select === 2 ? 'active' : ''}`} onClick={() => handleTab(2)}>
              <div className="item-label">案例</div>
              <div className="line" />
            </div>
            <div className={`nav-item handle ${select === 3 ? 'active' : ''}`} onClick={() => handleTab(3)}>
              <div className="item-label">关于我们</div>
              <div className="line" />
            </div>
            <div className={`nav-item handle ${select === 4 ? 'active' : ''}`} onClick={() => handleTab(4)}>
              <div className="item-label">联系我们</div>
              <div className="line" />
            </div>
          </div>
        </div>
      </div>
      {location.pathname.includes('/caseDetail') ? (
        <img className="bw-1" src={osData.caseBanner} alt="" />
      ) : (
        <Slider className="slider" {...bannerRef.current}>
          {osData.banners.map((i) => (
            <img key={i} className="bw-1" src={i} alt="" />
          ))}
        </Slider>
      )}
      <Outlet />
      <div id="conact" className="nav-bottom bw-1 d-flex-c ai-center">
        <div className="main-width d-flex-r">
          <div className="flex-1 d-flex-r jc-between">
            <div>
              <div className="font-16 mb-24">产品与服务</div>
              <div className="mb-8">软件测试</div>
              <div className="mb-8">定制开发</div>
              <div className="mb-8">服务类型</div>
              <div className="mb-8">核心优势</div>
            </div>
            <div>
              <div className="font-16 mb-24">案例</div>
              <div className="mb-8">电子商务行业app</div>
              <div className="mb-8">社交媒体行业app</div>
              <div className="mb-8">旅游和酒店行业app</div>
              <div className="mb-8">娱乐和游戏行业app</div>
            </div>
            <div>
              <div className="font-16 mb-24">关于我们</div>
              <div className="mb-8">企业文化</div>
              <div className="mb-8">发展历程</div>
              <div className="mb-8">公司介绍</div>
            </div>
            <div>
              <div className="d-flex-r ai-center mb-16">
                <img className="icon-phone" src="/pic/icon_phone.png" alt="" />
                <div>18310257003</div>
              </div>
              <div>办公地址：郑州市金水区王鼎国贸大厦3号楼516室</div>
            </div>
          </div>
          <div className="line" />
          <div className="zxppc">
            <div>
              <div className="ss">
                <a  href="https://work.weixin.qq.com/ca/cawcdeee5509e17981" target="_blank" className="itcast_click" rel="nofollow" style={{width:'80px',hight:'80px',marginRight:'30px',textDecoration:'none'}}>
                  <span className="icon_s"></span>
                  <img src="/pic/icon_customer.png" alt="" style={{width:'80px',hight:'80px',marginRight:'30px'}}/>
                  <p>&nbsp;&nbsp;立即咨询</p>
                </a>
              </div>
            </div>
          </div>
          <div className="qr-block text-center" >
            <img className="pic-qr mb-10" src="/pic/wx_qrcode.png" alt="" />
            <div className="mb-4">加微信</div>
            <div>免费获取方案及报价单</div>
          </div>
        </div>
      </div>
      <div className="copy-right bw-1 d-flex-r ai-center jc-center">
        <div className="main-width">©All Rights Reserved.&nbsp;&nbsp;&nbsp;&nbsp;<a style={{color: 'white',textDecoration:'none'}} href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023032105号</a> </div>
      </div>
    </div>
  );
}
